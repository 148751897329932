@font-face {
  font-family: 'franklin_gothic_bookregular';
  src: url('fonts/Franklin-Gothic/franklin-gothic-book_allfont.net-webfont.woff2') format('woff2'),
       url('fonts/Franklin-Gothic/franklin-gothic-book_allfont.net-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Andale Mono";
  src: url('fonts/Andale/andale_mono.woff') format('woff'),
       url('fonts/Andale/andale_mono.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Arial";
  src: url('fonts/Arial/arial.woff') format('woff'),
       url('fonts/Arial/arial.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Arial Black";
  src: url('fonts/Arial-Black/arial_black.woff') format('woff'),
       url('fonts/Arial-Black/arial_black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Baskerville";
  src: url('fonts/Baskerville/baskerville.woff') format('woff'),
       url('fonts/Baskerville/baskerville.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bradley Hand";
  src: url('fonts/Bradley-Hand/bradley_hand.woff') format('woff'),
  url('fonts/Bradley-Hand/bradley_hand.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brush Script MT";
  src: url('fonts/Brush-Script-MT/brush_script_mt.woff') format('woff'),
  url('fonts/Brush-Script-MT/brush_script_mt.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Chalkduster";
  src: url('fonts/Chalkduster/chalkduster.woff') format('woff'),
       url('fonts/Chalkduster/chalkduster.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Comic Sans MS";
  src: url('fonts/Comic-Sans-MS/comic_sans_ms.woff') format('woff'),
  url('fonts/Comic-Sans-MS/comic_sans_ms.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Courier";
  src: url('fonts/Courier/courier.woff') format('woff'),
  url('fonts/Courier/courier.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: "Fira-Sans";
    src: url('fonts/Fira-Sans/Fira-Sans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "Garamond";
  src: url('fonts/Garamond/garamond.woff') format('woff'),
  url('fonts/Garamond/garamond.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Georgia";
  src: url('fonts/Georgia/georgia.woff') format('woff'),
  url('fonts/Georgia/georgia.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url('fonts/Helvetica/helvetica.woff') format('woff'),
  url('fonts/Helvetica/helvetica.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue";
    src: url('fonts/HelveticaNeue/helveticaneue.woff') format('woff'),
         url('fonts/HelveticaNeue/helveticaneue.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

@font-face {
    font-family: "Impact";
    src: url('fonts/Impact/impact.woff') format('woff'),
    url('fonts/Impact/impact.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url('fonts/Lato/Lato.woff') format('woff'),
       url('fonts/Lato/Lato.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lucida Grande";
  src: url('fonts/Lucida-Grande/lucida_grande.woff') format('woff'),
  url('fonts/Lucida-Grande/lucida_grande.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Luminari";
  src: url('fonts/Luminari/luminari.woff') format('woff'),
  url('fonts/Luminari/luminari.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: "Manrope";
    src: url('fonts/Manrope/manrope.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "Marker Felt";
  src: url('fonts/Marker-Felt/marker_felt.woff') format('woff'),
       url('fonts/Marker-Felt/marker_felt.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: "Meta-Pro";
    src: url('fonts/Meta-Pro/Meta-Pro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "MierB-Book";
    src: url('fonts/MierB-Book/MierB-Book.woff') format('woff'),
         url('fonts/MierB-Book/MierB-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "Monaco";
  src: url('fonts/Monaco/monaco.woff') format('woff'),
  url('fonts/Monaco/monaco.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url('fonts/Montserrat/montserrat.woff') format('woff'),
    url('fonts/Montserrat/montserrat.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url('fonts/OpenSans/OpenSans-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "Palatino";
  src: url('fonts/Palatino/palatino.woff') format('woff'),
  url('fonts/Palatino/palatino.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: "Play";
    src: url('fonts/Play/Play.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "Revamped";
  src: url('fonts/Revamped/revamped.woff') format('woff'),
       url('fonts/Revamped/revamped.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url('fonts/Roboto/roboto.woff') format('woff'),
       url('fonts/Roboto/roboto.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sign Painter";
  src: url('fonts/Sign-Painter/sign_painter.woff') format('woff'),
       url('fonts/Sign-Painter/sign_painter.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stencilla';
  src: url('fonts/Stencilla/stencilla.woff') format('woff'),
       url('fonts/Stencilla/stencilla.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tahoma";
  src: url('fonts/Tahoma/tahoma.woff') format('woff'),
       url('fonts/Tahoma/tahoma.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Times New Roman";
  src: url('fonts/Times-New-Roman/times_new_roman.woff') format('woff'),
       url('fonts/Times-New-Roman/times_new_roman.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trade Gothic";
  src: url('fonts/Trade-Gothic/TradeGothicLT-Bold.woff') format('woff'),
       url('fonts/Trade-Gothic/TradeGothicLT-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trajan Pro";
  src: url('fonts/Trajan-Pro-Regular/trajan_pro_regular.woff') format('woff'),
       url('fonts/Trajan-Pro-Regular/trajan_pro_regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;     
}

@font-face {
  font-family: "Trebuchet MS";
  src: url('fonts/Trebuchet-MS/trebuchet_ms.woff') format('woff'),
       url('fonts/Trebuchet-MS/trebuchet_ms.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Verdana";
  src: url('fonts/Verdana/verdana.woff') format('woff'),
       url('fonts/Verdana/verdana.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html, body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'franklin_gothic_bookregular', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

html {
  overflow-y: auto;
}

.loader {
  position: absolute;
  top: calc(50% - 3em);
  left: calc(50% - 3em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.suspense-loader-wrap {
  background-color: #ddd;
}

.settings-loader-wrap {
  height: 10rem;
  background-color: #ddd;
}

.settings-loader {
  position: absolute;
  top: calc(50% - 3.25rem);
  left: calc(50% - 3em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

#si-wrapper .silc-btn {
  bottom: 60px !important;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.accordion-button:focus {
  box-shadow: none !important;
}